// extracted by mini-css-extract-plugin
export var Admin = "index-module--Admin--479zr";
export var Admin_Bold = "index-module--Admin_Bold---ZKrt";
export var Admin_ChallengeItem = "index-module--Admin_ChallengeItem--15wcM";
export var Admin_Challenges = "index-module--Admin_Challenges--kAIMj";
export var Admin_Description = "index-module--Admin_Description--uHEfk";
export var Admin_Heading = "index-module--Admin_Heading--m6NPw";
export var Admin_Image = "index-module--Admin_Image--wdmJP";
export var Admin_Patent = "index-module--Admin_Patent--fsI3r";
export var Admin_Title = "index-module--Admin_Title--xavL0";
export var Content = "index-module--Content--cy9X7";
export var Content_Annotation = "index-module--Content_Annotation--6IH0j";
export var Content_Description = "index-module--Content_Description--H0vTh";
export var Content_Heading = "index-module--Content_Heading--c3dsg";
export var Content_Item = "index-module--Content_Item--nsCSV";
export var Content_List = "index-module--Content_List--0mzeh";
export var Content_Title = "index-module--Content_Title---yEcU";
export var Content_Wrapper = "index-module--Content_Wrapper--qf4-Y";
export var Element = "index-module--Element--+yUZR";
export var Element_Description = "index-module--Element_Description--ou6JK";
export var Element_Green = "index-module--Element_Green--B2UmJ";
export var Element_Heading = "index-module--Element_Heading--xPgNx";
export var Element_Image = "index-module--Element_Image--cHrtk";
export var Element_Item = "index-module--Element_Item--hqwNs";
export var Element_ItemHeading = "index-module--Element_ItemHeading--Lk114";
export var Element_ItemReverse = "index-module--Element_ItemReverse--A2YFi";
export var Element_NewLine = "index-module--Element_NewLine--kZ2T0";
export var Element_Text = "index-module--Element_Text--Rl44l";
export var KenroCommon2PaneSectionLayout = "index-module--KenroCommon2PaneSectionLayout--WN2Pp";
export var Summary = "index-module--Summary--5GlOM";
export var Summary_Container = "index-module--Summary_Container--iR7E6";
export var Summary_Description = "index-module--Summary_Description--g8VuW";
export var Summary_Green = "index-module--Summary_Green--0E2yH";
export var Summary_Heading = "index-module--Summary_Heading--6dTGF";
export var Summary_HeadingLogo = "index-module--Summary_HeadingLogo--8SCgS";
export var Summary_HeadingNewLine = "index-module--Summary_HeadingNewLine--WZ5qN";
export var Summary_Image = "index-module--Summary_Image--6TQL4";
export var Summary_Left = "index-module--Summary_Left--MCAOc";
export var Summary_Right = "index-module--Summary_Right--wHoA9";
export var Vendor = "index-module--Vendor--gntjG";
export var Vendor_Container = "index-module--Vendor_Container--Bsjzk";
export var Vendor_Description = "index-module--Vendor_Description--WKNWR";
export var Vendor_Heading = "index-module--Vendor_Heading--13QPW";
export var Vendor_Item = "index-module--Vendor_Item--xPuFH";
export var Vendor_LinkToTop = "index-module--Vendor_LinkToTop--QJQkh";
export var Vendor_Logo = "index-module--Vendor_Logo--UylGm";
export var Vendor_LogoWrapper = "index-module--Vendor_LogoWrapper--yhjgu";
export var Vendor_Ribbon = "index-module--Vendor_Ribbon--9GOKD";
export var Vendor_RibbonEdgeAfter = "index-module--Vendor_RibbonEdgeAfter--bivE0";
export var Vendor_RibbonEdgeBefore = "index-module--Vendor_RibbonEdgeBefore--B27Y4";
export var Vendor_SubHeading = "index-module--Vendor_SubHeading--kZ8qA";
export var Vendor_Takashi = "index-module--Vendor_Takashi--yazFa";
export var Vendor_TakashiPhoto = "index-module--Vendor_TakashiPhoto--MeiXN";