import React from "react";
import { graphql, Link } from "gatsby";

import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";

type Props = {
  data: GatsbyKenroFeaturePageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title: "機能・コンテンツ | KENRO",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description: "KENRO (ケンロー) の機能・コンテンツについて説明しています。",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <div className={styles.Summary}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Summary_Container}>
            <div className={styles.Summary_Left}>
              <div>
                <h2 className={styles.Summary_Heading}>
                  <img
                    className={styles.Summary_HeadingLogo}
                    src={data?.logo_color?.publicURL || ""}
                    alt="KENRO(ケンロー)"
                  />
                  <span>の特長</span>
                  <br />
                  アプリケーションへの「
                  <span className={styles.Summary_Green}>攻撃</span>
                  」と
                  <br />
                  ソースコードの「
                  <span className={styles.Summary_Green}>修正</span>
                  」が実際に体験できます
                </h2>
                <div className={styles.Summary_Description}>
                  攻撃者の立場でアプリケーションを攻撃する 「
                  <b>ハッキング演習</b>
                  」と、
                  <br className={styles.Summary_HeadingNewLine} />
                  開発者の立場でソースコードを修正する「
                  <b>堅牢化演習</b>
                  」を通じて、
                  <br className={styles.Summary_HeadingNewLine} />
                  学習の定着を図ります。
                </div>
              </div>
            </div>
            <div className={styles.Summary_Right}>
              <img
                className={styles.Summary_Image}
                src={data?.feature?.publicURL || ""}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Element}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Element_Heading}>
            セキュアコーディングを定着させる3つの要素
          </h2>
          <div className={styles.Element_Item}>
            <img
              className={styles.Element_Image}
              src={data?.text?.publicURL || ""}
              alt=""
            />
            <div className={styles.Element_Text}>
              <h3 className={styles.Element_ItemHeading}>技術・脆弱性の解説</h3>
              <p className={styles.Element_Description}>
                脆弱性が生まれる背景や原理を学ぶための基礎的なテキスト教材です。
              </p>
              <p className={styles.Element_Description}>
                脆弱性がどのようなリスクを持つか、どのようにして攻撃に利用されるのか、そしてその脆弱性の対策手法を
                <b>初学者にも分かりやすく詳解しています。</b>
              </p>
            </div>
          </div>
          <div className={styles.Element_ItemReverse}>
            <img
              className={styles.Element_Image}
              src={data?.hacking?.publicURL || ""}
              alt=""
            />
            <div className={styles.Element_Text}>
              <h3 className={styles.Element_ItemHeading}>ハッキング演習</h3>
              <p className={styles.Element_Description}>
                攻撃者の立場で、
                <b>脆弱なWebアプリケーションを実際に攻撃する演習</b>
                です。
              </p>
              <p className={styles.Element_Description}>
                演習を通して攻撃者の立場を経験することで、開発時にセキュリティを意識することの重要性や、脆弱性によるリスクを体験できます。
              </p>
            </div>
          </div>
          <div className={styles.Element_Item}>
            <img
              className={styles.Element_Image}
              src={data?.defence?.publicURL || ""}
              alt=""
            />
            <div className={styles.Element_Text}>
              <h3 className={styles.Element_ItemHeading}>堅牢化演習</h3>
              <p className={styles.Element_Description}>
                実際の開発現場に近いソースコードを用いて、
                <b>脆弱なWebアプリケーションを修正する演習</b>
                です。
              </p>
              <p className={styles.Element_Description}>
                独自のジャッジシステムによって、修正されたコードに対して自動でテストが実行され、脆弱性が修正されたかどうかを自動で判定します。
                <span className={styles.Element_Description}>
                  （特許第7463021号）
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Admin}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Admin_Heading}>
            大規模な研修やチーム学習での定着をサポート
          </h2>
          <div className={styles.Admin_Challenges}>
            <div className={styles.Admin_ChallengeItem}>
              <img
                className={styles.Admin_Image}
                src={data?.admin1?.publicURL || ""}
                alt="受講者のグループ管理機能の画像"
              />
              <h3 className={styles.Admin_Title}>受講者のグループ管理</h3>
              <p className={styles.Admin_Description}>
                グループごとに受講状況を管理したり、CSVファイルを用いた一括登録・更新をしたりすることが可能です。
              </p>
            </div>
            <div className={styles.Admin_ChallengeItem}>
              <img
                className={styles.Admin_Image}
                src={data?.admin2?.publicURL || ""}
                alt="受講のリマインド通知機能の画像"
              />
              <h3 className={styles.Admin_Title}>受講のリマインド通知</h3>
              <p className={styles.Admin_Description}>
                受講完了予定日を設定し、完了していない受講者にリマインドのためのメール通知を自動で送ることができます。
              </p>
            </div>
            <div className={styles.Admin_ChallengeItem}>
              <img
                className={styles.Admin_Image}
                src={data?.admin3?.publicURL || ""}
                alt="進捗管理・成績分析機能の画像"
              />
              <h3 className={styles.Admin_Title}>進捗管理・成績分析</h3>
              <p className={styles.Admin_Description}>
                演習の進捗状況をグラフで把握することができ、演習の正答状況のデータを出力して分析できます。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Content}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Content_Heading}>KENROで学べること</h2>
          <div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>
                基礎的な脆弱性に関するコンテンツ
              </h3>
              <p className={styles.Content_Description}>
                OWASP Top 10
                中に含まれる脆弱性をはじめ、Webアプリケーションにおける代表的な脆弱性を網羅的に学ぶことが可能です。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>SQL Injection</li>
                <li className={styles.Content_Item}>
                  Cross-Site Scripting (XSS)
                </li>
                <li className={styles.Content_Item}>
                  Cross-Site Request Forgery (CSRF)
                </li>
                <li className={styles.Content_Item}>OS Command Injection</li>
                <li className={styles.Content_Item}>Directory Traversal</li>
                <li className={styles.Content_Item}>
                  Insecure Deserialization
                </li>
                <li className={styles.Content_Item}>
                  XML eXternal Entity (XXE)
                </li>
                <li className={styles.Content_Item}>Open Redirection</li>
                <li className={styles.Content_Item}>Clickjacking</li>
                <li className={styles.Content_Item}>Header Injection</li>
              </ul>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>発展的なコンテンツ</h3>
              <p className={styles.Content_Description}>
                ご要望に応じてコンテンツを随時追加しています。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>
                  GraphQLの基礎とセキュリティ
                </li>
                <li className={styles.Content_Item}>JWTの基礎とセキュリティ</li>
                <li className={styles.Content_Item}>
                  WebSocketの基礎とセキュリティ
                </li>
              </ul>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>対応プログラミング言語</h3>
              <p className={styles.Content_Description}>
                基礎的な脆弱性に関するコンテンツの「堅牢化演習」にて対応しているプログラミング言語です。ご要望に応じて随時追加しています。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>Java</li>
                <li className={styles.Content_Item}>Go</li>
                <li className={styles.Content_Item}>Python</li>
                <li className={styles.Content_Item}>Ruby (Rails)</li>
                <li className={styles.Content_Item}>PHP</li>
                <li className={styles.Content_Item}>C&#035;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Vendor}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Vendor_Ribbon}>
            <img
              className={styles.Vendor_RibbonEdgeBefore}
              src={data?.ribbon_edge?.publicURL || ""}
              alt=""
            />
            開発・運営チームの紹介
            <img
              className={styles.Vendor_RibbonEdgeAfter}
              src={data?.ribbon_edge?.publicURL || ""}
              alt=""
            />
          </div>
          <h2 className={styles.Vendor_Heading}>
            セキュリティ診断の実績豊富な
            <br />
            プロフェッショナルが開発・運営しています
          </h2>
          <div className={styles.Vendor_Container}>
            <div className={styles.Vendor_Item}>
              <h3 className={styles.Vendor_SubHeading}>運営会社</h3>
              <h4 className={styles.Vendor_LogoWrapper}>
                <img
                  className={styles.Vendor_Logo}
                  src={data?.flatt_security_logo?.publicURL || ""}
                  alt="Flatt Securityロゴ"
                />
              </h4>
              <p className={styles.Vendor_Description}>
                <Link className={styles.Vendor_LinkToTop} to="/">
                  Flatt Security
                </Link>
                はWebアプリケーションからクラウド・IoTまで幅広くセキュリティ診断サービスを提供するセキュリティのプロフェッショナル企業です。
              </p>
              <p className={styles.Vendor_Description}>
                様々なお客様のサービスを診断した知見を活かし、セキュア開発コンサルティングサービスやKENROの提供を行っています。
              </p>
            </div>
            <div className={styles.Vendor_Item}>
              <h3 className={styles.Vendor_SubHeading}>コンテンツ監修</h3>
              <div className={styles.Vendor_Takashi}>
                <img
                  className={styles.Vendor_TakashiPhoto}
                  src={data?.takashi?.publicURL || ""}
                  alt="堅牢化演習"
                />
                <div>
                  <p>セキュリティエンジニア</p>
                  <h4>米内 貴志</h4>
                </div>
              </div>
              <p className={styles.Vendor_Description}>
                株式会社Flatt Security
                所属。一般社団法人セキュリティ・キャンプ協議会やSECCON実行委員会の一員として、情報セキュリティ技術の教育活動を行っています。
              </p>
              <p className={styles.Vendor_Description}>
                著書「Webブラウザセキュリティ ―
                Webアプリケーションの安全性を支える仕組みを整理する」
              </p>
            </div>
          </div>
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export const query = graphql`
  query KenroFeaturePage {
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
    flatt_security_logo: file(relativePath: { eq: "flatt_security_logo.svg" }) {
      publicURL
    }
    admin1: file(relativePath: { eq: "kenro/admin1.svg" }) {
      publicURL
    }
    admin2: file(relativePath: { eq: "kenro/admin2.svg" }) {
      publicURL
    }
    admin3: file(relativePath: { eq: "kenro/admin3.svg" }) {
      publicURL
    }
    feature: file(relativePath: { eq: "kenro/feature.svg" }) {
      publicURL
    }
    hacking: file(relativePath: { eq: "kenro/hacking.svg" }) {
      publicURL
    }
    defence: file(relativePath: { eq: "kenro/defence.svg" }) {
      publicURL
    }
    text: file(relativePath: { eq: "kenro/text.svg" }) {
      publicURL
    }
    takashi: file(relativePath: { eq: "kenro/takashi.jpg" }) {
      publicURL
    }
    ribbon_edge: file(relativePath: { eq: "kenro/ribbon_edge.svg" }) {
      publicURL
    }
    ogp: file(relativePath: { eq: "kenro/ogp.png" }) {
      publicURL
    }
  }
`;

export default Container;
